import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLayerGroup, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import theme from '../../utils/themeconstants';
import Link from '../elements/link';
import Mailchimp from '../elements/mailchimp';
import iPhoneBezelFile from '../../images/iPhoneBezel.png';

import ActivityBubbles from './index_activitybubbles';
import AppCarpet from './index_appcarpet';

const PHONE_WIDTH = 200;

const Gradient = styled.div`
  height: 100vh;
  position: relative;
  width: 100%;
  background-image: radial-gradient(circle at bottom center, #FFFFFF, #F7F9FC);
  overflow: hidden;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    height: auto;
  }
`;

const appear = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const StageTitle = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.space.lg}px;
  animation: ${appear} 2s ease;
  animation-delay: 0.8s;
  opacity: 0;
  animation-fill-mode: forwards;

  h1 {
    font-size: 42px;
    color: ${theme.colors.primary};
  }
  p{
    line-height: 1.2;
  }
  h3 {
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 8px;
  }
`;

const AppContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
`;

const PhoneBezel = styled.img`
  position: relative;
  margin: 0;
  width: ${PHONE_WIDTH}px;
  z-index: 99;
`;

const PhoneScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border-radius: 50px;
  background-image: radial-gradient(circle at bottom center, #C6ECFA, #46C1ED, #1D73D3);
  background-repeat: no-repeat;
  background-size: 90% 94%;
  background-position: center center; 
  z-index: 94;
`;

const ActivityContainer = styled.div`
  position: absolute;
  animation: ${appear} 1s ease;
`;

const UICenter = styled.div`
  position: absolute;
  bottom: 32px;
  left: ${PHONE_WIDTH / 2 + -11}px;
  z-index: 95;
  background-image: url();
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UIRight = styled.div`
  position: absolute;
  bottom: 28px;
  right: 32px;
  z-index: 95;
`;

const UILeft = styled.div`
  position: absolute;
  bottom: 28px;
  left: 32px;
  z-index: 95;
`;

const steps = [
  [4, 4],
  [4, 3],
  [3, 3],
  [3, 4],
];

export default class StageApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appContainerWidth: 0,
      appContainerHeight: 0,
      appCarpetCenterCoordinate: [3, 4],
    };
  }

  updateDimensions = () => {
    const appContainer = document.getElementById('appcontainer');
    const appContainerWidth = appContainer.clientWidth;
    const appContainerHeight = appContainer.clientHeight;
    this.setState({
      appContainerWidth,
      appContainerHeight,
    });
  }

  setAppCenter = (center) => {
    this.setState({
      appCarpetCenterCoordinate: center,
    });
  }

  start = (counter) => {
    if (counter < steps.length) {
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        counter += 1;
        this.setAppCenter(steps[counter - 1]);
        this.start(counter);
      }, 5000);
    } else {
      this.start(0);
    }
  }

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    this.start(0);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const {
      appContainerWidth,
      appContainerHeight,
      appCarpetCenterCoordinate,
    } = this.state;
    return (
      <Gradient>
        <GridContainer>
          <Row>
            <Col push={{ md: 6 }} md={6}>
              <AppContainer
                id="appcontainer"
              >
                <ActivityContainer>
                  <PhoneScreen />
                  <ActivityBubbles
                    width={PHONE_WIDTH}
                    containerHeight={appContainerHeight / 3}
                    maxSpawns={steps.length}
                  />
                  <UILeft>
                    <FontAwesomeIcon color="white" size="sm" icon={faDotCircle} />
                  </UILeft>
                  <UICenter>
                    <FontAwesomeIcon color="white" size="lg" icon={faUserCircle} />
                  </UICenter>
                  <UIRight>
                    <FontAwesomeIcon color="white" size="sm" icon={faLayerGroup} />
                  </UIRight>
                  <PhoneBezel
                    src={iPhoneBezelFile}
                    alt="iPhone Bezel"
                  />

                </ActivityContainer>
                <AppCarpet
                  centerCoordinate={appCarpetCenterCoordinate}
                  width={appContainerWidth}
                  height={(appContainerHeight - (PHONE_WIDTH * 2)) / 2}
                />
              </AppContainer>
            </Col>
            <Col pull={{ md: 6 }} md={6}>
              <StageTitle>
                <h1>The climate impact of every choice you make.</h1>
                <p>
                  Tomorrow automatically calculates the climate impact of your daily
                   choices by connecting to apps and services you already use.
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontSize: '12px' }}>
                    beta release: fall 2019
                  </span>
                </p>
                <Mailchimp
                  color={theme.colors.primary}
                  action="https://europe-west1-tmrowapp.cloudfunctions.net/mailchimp-proxy?u=8689fc68cd6c50ec671a95afb&amp;id=96da98142a"
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'Join the waitlist (email)',
                      type: 'email',
                      required: true,
                    },
                  ]}
                />
                <p style={{ margin: 0, fontSize: '14px' }}>
                  Help us{' '}
                  <Link outward href="https://github.com/tmrowco/tmrowapp-contrib" target="_blank"> build integrations </Link>
                  that matter on {' '}
                  <FontAwesomeIcon size="sm" icon={faGithub} />
                  {' '} github.
                </p>
              </StageTitle>
            </Col>
          </Row>
        </GridContainer>
      </Gradient>
    );
  }
}
