/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';

import * as d3 from 'd3-force';


const ANIM_SPEED = 1000;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => (props.width * 2) + ((props.containerHeight * 3) - (props.width * 2)) / 4}px;
  border-radius: 45px;
  overflow: hidden;
  background-color: transparent;
`;

const appear = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Circle = styled.div.attrs(props => ({
  style: {
    left: `${props.x - props.radius}px`,
    bottom: `${props.y}px`,
  },
}))`
  background-color: white;
  position: absolute;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  width: ${props => 2.0 * props.radius}px;
  height: ${props => 2.0 * props.radius}px;
  border-radius: ${props => 2.0 * props.radius}px;
  text-align: center;
  line-height: ${props => 2.0 * props.radius}px;
  z-index: 95;
  animation: ${appear} ${ANIM_SPEED}ms ease;
`;

// This object will be modified inplace by the d3 forceSimulation
export default class extends PureComponent {
  constructor() {
    super();
    this.nodes = [];
    this.simulation = d3.forceSimulation()
      .force('charge', d3.forceManyBody().strength(-20))
      .force('collide',
        d3.forceCollide()
          .strength(0.5)
          .radius(d => d.radius * 1.2)
          .iterations(1))
      .nodes(this.nodes)
      .alphaDecay(0)
      .on('tick', () => {
        this.forceUpdate();
      });
  }

  componentDidMount() {
    setTimeout(() => {
      this.startSpawn(0);
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.width !== this.props.width
      || nextProps.containerHeight !== this.props.containerHeight) {
      this.simulation = this.simulation
        .force('forceX', d3.forceX().strength(0.05).x(nextProps.width / 2))
        .force('forceY', d3.forceY().strength(0.05).y(nextProps.containerHeight));
    }
  }

  componentWillUnmount() {
    this.simulation.stop();
  }

  startSpawn = (counter) => {
    if (counter === 0) {
      counter += 1;
      this.spawn();
      this.startSpawn(counter);
    } else if (counter <= this.props.maxSpawns) {
      setTimeout(() => {
        counter += 1;
        this.spawn();
        this.startSpawn(counter);
      }, 5000);
    } else {
      while (this.nodes.length) {
        this.nodes.pop();
      }
      this.startSpawn(0);
    }
  }

  spawn() {
    if (!this.props.width || !this.props.containerHeight) {
      return;
    }
    const newNodes = [];
    let i;
    const times = Math.round(Math.random() * 2) + 1;
    for (i = 0; i < times; i += 1) {
      const node = {
        index: this.nodes.length,
        x: this.props.width / 2,
        y: 0,
        radius: 1 + ((Math.random() * 2) + 1) * 7,
        fx: this.props.width / 2,
        fy: 0,
      };
      newNodes.push(node);
      this.nodes.push(node);
    }
    setTimeout(() => {
      newNodes.forEach((newNode) => {
        newNode.fx = undefined;
        newNode.fy = undefined;
      });
    }, ANIM_SPEED);
    this.simulation
      .nodes(this.nodes);
  }

  render() {
    return (
      <Container
        containerHeight={this.props.containerHeight}
        width={this.props.width}
      >
        { this.nodes.map(d => (
          <Circle
            key={d.index}
            x={d.x}
            y={d.y}
            radius={d.radius}
          />
        )) }
      </Container>
    );
  }
}
