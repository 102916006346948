import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Stage from '../components/pagecomponents/index_stage';

const Company = () => (
  <Layout dark>
    <SEO title="Tomorrow - The Climate Impact of Every Choice You Make." />
    <Stage />
  </Layout>
);

export default Company;
