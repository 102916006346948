import React from 'react';
import styled, { keyframes } from 'styled-components';
import { scaleLinear } from 'd3-scale';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  transform: rotate3d(1,0,0,80deg);
`;

const AppCarpetContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(${props => props.offsetx}px,${props => props.offsety}px);
  transition: transform 1s;
  transition-timing-function: ease-in-out;
`;

const appApear = keyframes`
  from {
    transform: rotateZ(55deg) scale(0);
  }

  to {
    transform: rotateZ(0deg) scale(${props => props.size / 60});
  }
`;

const App = styled.div.attrs(props => ({
  style: {
    left: `${props.x - props.size / 2}px`,
    bottom: `${props.y - props.size / 2}px`,
    borderRadius: `${props.size / 4}px`,
    opacity: `${props.opacity}`,
    transform: `rotateZ(0deg) scale(${props.size / 60})`,
  },
}))`
  position: absolute;
  box-shadow: 0px 20px 30px rgba(0,0,0,0.10);
  width: 60px;
  height: 60px;
  /* transition all solves the jupy animation but is bad for performance */
  transition: transform 1.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  animation: ${appApear} 1.4s ease;
`;


class AppCarpet extends React.PureComponent {
  render() {
    const { width, height, centerCoordinate } = this.props;

    // on first render, when it doesn't have a width or height yet, it should not render anything.
    if (!width || !height) {
      return null;
    }

    const itemsPerRow = [5, 6, 7, 8, 7, 6, 5];
    const maxItemsPerRow = Math.max(...itemsPerRow);
    const Apps = [];
    const padding = 16;
    const size = 60;

    const getx = ([rowIndex, colIndex]) => (
      colIndex * (size + 2 * padding)
      + ((maxItemsPerRow - itemsPerRow[rowIndex]) * (size + 2 * padding) / 2)
    );

    const gety = ([rowIndex]) => (rowIndex * (size + 2 * padding));

    const cx = getx(centerCoordinate);
    const cy = gety(centerCoordinate);

    const sizeScale = scaleLinear()
      .domain([0, maxItemsPerRow / 2 * (2 * padding + size)])
      .range([size, 0.25 * size]);

    const opacityScale = scaleLinear()
      .domain([0, maxItemsPerRow / 2 * (1.5 * padding + size)])
      .range([1, 0]);

    itemsPerRow.forEach((rowItems, rowIndex) => {
      // We need to render `rowItems` here
      for (let colIndex = 0; colIndex < rowItems; colIndex += 1) {
        const x = getx([rowIndex, colIndex]);
        const y = gety([rowIndex, colIndex]);
        const distanceToCenter = Math.sqrt(((cx - x) ** 2) + ((cy - y) ** 2));
        Apps.push(
          <App
            key={Apps.length}
            delay={Apps.length}
            x={x}
            y={y}
            size={sizeScale(distanceToCenter)}
            opacity={opacityScale(distanceToCenter)}
            color={colIndex === centerCoordinate[1] && rowIndex === centerCoordinate[0] ? 'blue' : 'red'}
          >
            <img
              src={`applogos/app-${Apps.length}.jpg`}
              alt={`app-${Apps.length}`}
            />
          </App>
          ,
        );
      }
    });
    return (
      <Container
        height={height}
        width={width}
      >
        <AppCarpetContainer
          offsetx={width / 2 - cx}
          offsety={-(height / 2 - cy)}
          transformOriginY={-cy}
        >
          {
            Apps
          }
        </AppCarpetContainer>
      </Container>

    );
  }
}

export default AppCarpet;
